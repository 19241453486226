import http from 'http.js';
import query from 'query.js';
import {withScope, captureMessage} from '@sentry/browser';
import {Severity} from '@sentry/types';

// [TODO] In the future this config file should be seperated as json file
// Structure
// page
//   +- section
//      +- gaObjectType: 'GA object type'
//      +- items.. : 'GA selector'
export const analyticsConfig = {
    api: '/api/base/v1/a/t/',
    pages: {
        practice: {
            locationSection: {
                gaObjectType: 'location-section',
                map: 'map',
                address: 'address-link',
                phone: 'call-now-button-secondary',
                fax: 'show-fax-number',
                web: 'website-link',
                selector: 'selector',
                openingHours: 'opening-hours',
                paymentOptions: 'payment-options',
                serviceOptions: 'service-options',
                practiceGroupLink: 'practice-group-link',
            },
        },
    },
};

/**
 * Logs the occurred error to sentry.
 * @param {Error} error - Error passed by the caller
 * @param {object} eventData - Data the tracking event has been called with
 */
function logSentryAnalyticsError(error, eventData = {}) {
    withScope((scope) => {
        if (eventData) {
            scope.setContext('event data', eventData);
        }
        scope.setLevel(Severity.Warning);
        // Group analytics errors by analytics error, then top level
        // path (e.g. all directory grouped as directory, all profiles
        // as profile
        scope.setFingerprint(['analytics/track', query.pathSegment(0)]);
        try {
            error = JSON.parse(error.message);
        } catch (ex) {
            error = {};
        }
        scope.setTag('status_code', error['status']);
        if (error.response) {
            try {
                const response = JSON.parse(error.response);
                scope.setTag('errors', response.errors);
            } catch (ex) {
                // nothing to tag
            }
        }

        captureMessage('Analytics tracking error');
    });
}

export function trackEvent(url, data) {
    http.post({
        url,
        data,
    }).catch((error) => logSentryAnalyticsError(error, {url, data}));
}

export default {
    async track(event, data, eventId) {
        if (eventId) {
            data['event_id'] = eventId;
        }
        data = JSON.stringify(data);
        try {
            return await http.post({
                url: analyticsConfig.api,
                data: {
                    event,
                    data,
                },
            });
        } catch (ex) {
            logSentryAnalyticsError(ex, {event, data, eventId});
        }
    },
};
